<template>
  <div id="print-medical-records" class="header">
    <div class="card-wrapper">
      <div class="text-wrapper">
        <div class="div-icon">
          <Printer />
        </div>
        <div class="text">
          <p>Documentos disponíveis para impressão imediata</p>
        </div>
      </div>

      <div class="print">
        <b-button
          v-show="!(loading || uploadedInfo.loading) && !(uploadedInfo.url && uploadedInfo.printed)"
          variant="link"
          @click="$bvModal.show('print-medical-records-modal')"
          >Imprimir
        </b-button>
      </div>
    </div>

    <v-print-medical-records-modal
      :attendanceReview="attendanceReview"
      :attendanceId="attendanceId"
      :formGroups="formGroups"
      :medicines="medicines"
      :medicinesAntimicrobianos="medicinesAntimicrobianos"
      :patientData="patientData"
      :professionalData="professionalData"
      :professionalCrm="professionalCrm"
      :clinicsData="clinicsData"
    />
  </div>
</template>

<script>
import { isEqual, cloneDeep } from 'lodash'
import Printer from '@/assets/icons/printer.svg'
import PrintMedicalRecordsModal from '@/views/Patients/PrintMedicalRecordsModal.vue'

export default {
  name: 'CardPrintMedicalRecords',
  props: {
    attendanceReview: Object,
    attendanceId: String,
    formGroups: Object,
    medicines: Object,
    medicinesAntimicrobianos: Object,
    patientData: Object,
    professionalData: Object,
    professionalCrm: Array,
    clinicsData: Object,
    getAttendanceFiles: Function
  },
  data() {
    return {
      loading: null,
      uploadedInfo: {
        url: null,
        totalDocuments: null,
        totalDocsUploaded: null,
        toPrint: null,
        loading: false,
        docsToPrint: [],
        printed: false,
        attendanceReview: { ...this.attendanceReview },
        formGroups: this.getFormValues(this.formGroups)
      },
      isEqual
    }
  },
  components: {
    Printer,
    'v-print-medical-records-modal': PrintMedicalRecordsModal
  },
  methods: {
    getFormValues(form) {
      const newForm = cloneDeep(form, {}, true)
      const values = []
      Object.keys(newForm).map(key => {
        Object.keys(newForm[key].forms).map(formKey => {
          if (key === 'Conduta') {
            if (
              newForm[key].forms[formKey].value &&
              newForm[key].forms[formKey].value.length
            ) {
              values.push(newForm[key].forms[formKey].value)
            } else if (!newForm[key].forms[formKey].value) {
              values.push(newForm[key].forms[formKey].fields)
            }
          }
        })
      })
      return values
    }
  },
  watch: {
    attendanceReview: {
      deep: true,
      handler(value) {
        if (!isEqual(this.uploadedInfo.attendanceReview, value)) {
          this.uploadedInfo.attendanceReview = { ...value }
          this.uploadedInfo.docsToPrint = []
          this.uploadedInfo.printed = false
        }
      }
    },
    formGroups: {
      deep: true,
      handler(value) {
        const newValues = this.getFormValues(value)
        if (
          JSON.stringify(newValues) !==
          JSON.stringify(this.uploadedInfo.formGroups)
        ) {
          this.uploadedInfo.formGroups = newValues
          this.uploadedInfo.docsToPrint = []
          this.uploadedInfo.printed = false
        }
      }
    }
  }
}
</script>

<style lang="scss">
#print-medical-records {
  background: var(--blue-100);
  margin-top: 50px;
  border-radius: 8px;
  margin-right: 5px;
  padding: 16px 20px;

  .card-wrapper {
    width: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
  }

  .text-wrapper {
    display: inline-flex;
    align-items: center;
    gap: 20px;
  }

  .div-icon {
    svg {
      @media (max-width: 1000px) {
        width: 15px;
      }
      width: 30px;
    }
  }

  .text {
    p {
      font-family: 'Nunito Sans';
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 150%;
      color: var(--type-active);
    }
  }

  .print {
    .spinner-border {
      color: var(--orange);
    }

    .loading {
      font-family: 'Nunito Sans';
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 150%;
      color: var(--type-active);
    }
  }
}
</style>
