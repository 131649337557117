<template>
  <div>
    <b-modal
      id="print-medical-records-modal"
      hide-header
      hide-footer
      centered
      size="lg"
      @show="onShow"
    >
      <div class="header d-flex flex-row justify-content-between">
        <p class="title">Impressão de documentos</p>
        <Close
          class="close"
          @click="$bvModal.hide('print-medical-records-modal')"
        />
      </div>
      <div class="body">
        <div>
          <div>
            <p class="text mt-3 mb-3" id="title">
              Selecione na lista abaixo quais documentos deseja
              <strong>imprimir</strong>.
            </p>
            <table aria-describedby="title">
              <tr>
                <th class="table-header">Documentos</th>
                <th class="table-header text-center">
                  <strong>Imprimir</strong>
                </th>
              </tr>

              <tr>
                <td colspan="3">
                  <hr class="hr-table" />
                </td>
                <td colspan="2">
                  <hr class="hr-table" />
                </td>
              </tr>

              <tr>
                <td class="tdLabel">Solicitação de exames</td>
                <td class="text-center">
                  <label class="checkbox" align="center">
                    <input
                      type="checkbox"
                      v-model="checkAttendanceReview.solicitacaoDeExames"
                      :disabled="!Boolean(attendanceReview.solicitacaoDeExames)"
                    />
                    <span class="checkmark" id="solicitacao-de-exames">
                      <Check />
                    </span>
                    <b-tooltip
                      v-if="!Boolean(attendanceReview.solicitacaoDeExames)"
                      target="solicitacao-de-exames"
                      placement="top"
                    >
                      Para imprimir este documento, <br />
                      preencha o campo dentro da consulta
                    </b-tooltip>
                  </label>
                </td>
              </tr>

              <tr>
                <td colspan="3">
                  <hr class="hr-table" />
                </td>
                <td colspan="2">
                  <hr class="hr-table" />
                </td>
              </tr>

              <tr>
                <td class="tdLabel">
                  Solicitação de APA (Avaliação Pré Anestésica)
                </td>
                <td class="text-center">
                  <label class="checkbox" align="center">
                    <input
                      type="checkbox"
                      v-model="checkAttendanceReview.solicitacaoDeAPA"
                      :disabled="!Boolean(attendanceReview.solicitacaoDeAPA)"
                    />
                    <span id="apa" :class="`checkmark ${!Boolean(attendanceReview.solicitacaoDeAPA) ? 'checkmark-disabled' : ''}`">
                      <Check />
                    </span>
                    <b-tooltip
                      v-if="!Boolean(attendanceReview.solicitacaoDeAPA)"
                      target="apa"
                      placement="top"
                    >
                      Para imprimir este documento, <br />
                      preencha o campo dentro da consulta
                    </b-tooltip>
                  </label>
                </td>
              </tr>

              <tr>
                <td colspan="3">
                  <hr class="hr-table" />
                </td>
                <td colspan="2">
                  <hr class="hr-table" />
                </td>
              </tr>

              <tr>
                <td class="tdLabel">Solicitação de cirurgia</td>
                <td class="text-center">
                  <label class="checkbox" align="center">
                    <input
                      type="checkbox"
                      v-model="checkAttendanceReview.solicitacaoDeCirurgia"
                      :disabled="!Boolean(attendanceReview.solicitacaoDeCirurgia)"
                    />
                    <span class="checkmark" id="solicitacao-de-cirurgia">
                      <Check />
                    </span>
                    <b-tooltip
                      v-if="!Boolean(attendanceReview.solicitacaoDeCirurgia)"
                      target="solicitacao-de-cirurgia"
                      placement="top"
                    >
                      Para imprimir este documento, <br />
                      preencha o campo dentro da consulta
                    </b-tooltip>
                  </label>
                </td>
              </tr>

              <tr>
                <td colspan="3">
                  <hr class="hr-table" />
                </td>
                <td colspan="2">
                  <hr class="hr-table" />
                </td>
              </tr>

              <tr>
                <td class="tdLabel">Encaminhamento</td>
                <td class="text-center">
                  <label class="checkbox" align="center">
                    <input
                      type="checkbox"
                      v-model="checkAttendanceReview.encaminhamento"
                      :disabled="!Boolean(attendanceReview.encaminhamento)"
                    />
                    <span class="checkmark" id="encaminhamento">
                      <Check />
                    </span>
                    <b-tooltip
                      v-if="!Boolean(attendanceReview.encaminhamento)"
                      target="encaminhamento"
                      placement="top"
                    >
                      Para imprimir este documento, <br />
                      preencha o campo dentro da consulta
                    </b-tooltip>
                  </label>
                </td>
              </tr>

              <tr>
                <td colspan="3">
                  <hr class="hr-table" />
                </td>
                <td colspan="2">
                  <hr class="hr-table" />
                </td>
              </tr>

              <tr>
                <td class="tdLabel">Prescrição Medicamentosa</td>
                <td class="text-center">
                  <label class="checkbox" align="center">
                    <input
                      type="checkbox"
                      v-model="checkAttendanceReview.prescricaoMedicamentosa"
                      :disabled="!Boolean(attendanceReview.prescricaoMedicamentosa)"
                    />
                    <span class="checkmark" id="prescricao-medicamentosa">
                      <Check />
                    </span>
                    <b-tooltip
                      v-if="!Boolean(attendanceReview.prescricaoMedicamentosa)"
                      target="prescricao-medicamentosa"
                      placement="top"
                    >
                      Para imprimir este documento, <br />
                      preencha o campo dentro da consulta
                    </b-tooltip>
                  </label>
                </td>
              </tr>

              <tr>
                <td colspan="3">
                  <hr class="hr-table" />
                </td>
                <td colspan="2">
                  <hr class="hr-table" />
                </td>
              </tr>

              <tr>
                <td class="tdLabel">Prescrição do óculos</td>
                <td class="text-center">
                  <label class="checkbox" align="center">
                    <input
                      type="checkbox"
                      v-model="checkAttendanceReview.prescricaoDeOculos"
                      :disabled="!Boolean(attendanceReview.prescricaoDeOculos)"
                    />
                    <span class="checkmark" id="prescricao-de-oculos">
                      <Check />
                    </span>
                    <b-tooltip
                      v-if="!Boolean(attendanceReview.prescricaoDeOculos)"
                      target="prescricao-de-oculos"
                      placement="top"
                    >
                      Para imprimir este documento, <br />
                      preencha o campo dentro da consulta
                    </b-tooltip>
                  </label>
                </td>
              </tr>

              <tr>
                <td colspan="3">
                  <hr class="hr-table" />
                </td>
                <td colspan="2">
                  <hr class="hr-table" />
                </td>
              </tr>

              <tr>
                <td class="tdLabel">Prescrição de lentes de contato</td>
                <td class="text-center">
                  <label class="checkbox" align="center">
                    <input
                      type="checkbox"
                      v-model="checkAttendanceReview.prescricaoDeLentes"
                      :disabled="!Boolean(attendanceReview.prescricaoDeLentes)"
                    />
                    <span
                      class="checkmark"
                      id="prescricao-de-lentes-de-contato"
                    >
                      <Check />
                    </span>
                    <b-tooltip
                      v-if="!Boolean(attendanceReview.prescricaoDeLentes)"
                      target="prescricao-de-lentes-de-contato"
                      placement="top"
                    >
                      Para imprimir este documento, <br />
                      preencha o campo dentro da consulta
                    </b-tooltip>
                  </label>
                </td>
              </tr>

              <tr>
                <td colspan="3">
                  <hr class="hr-table" />
                </td>
                <td colspan="2">
                  <hr class="hr-table" />
                </td>
              </tr>

              <tr>
                <td class="tdLabel">Orientação ao paciente</td>
                <td class="text-center">
                  <label class="checkbox" align="center">
                    <input
                      type="checkbox"
                      v-model="checkAttendanceReview.posOperatorio"
                      :disabled="!Boolean(attendanceReview.posOperatorio)"
                    />
                    <span class="checkmark" id="orientacao-ao-paciente">
                      <Check />
                    </span>
                    <b-tooltip
                      v-if="!Boolean(attendanceReview.posOperatorio)"
                      target="orientacao-ao-paciente"
                      placement="top"
                    >
                      Para imprimir este documento, <br />
                      preencha o campo dentro da consulta
                    </b-tooltip>
                  </label>
                </td>
              </tr>

              <tr>
                <td colspan="3">
                  <hr class="hr-table" />
                </td>
                <td colspan="2">
                  <hr class="hr-table" />
                </td>
              </tr>

              <tr>
                <td class="tdLabel">Declaração de comparecimento</td>
                <td class="text-center">
                  <label class="checkbox" align="center">
                    <input
                      type="checkbox"
                      v-model="checkAttendanceReview.declaracaoDeComparecimento"
                      :disabled="!Boolean(attendanceReview.declaracaoDeComparecimento)"
                    />
                    <span class="checkmark" id="declaracao-de-comparecimento">
                      <Check />
                    </span>
                    <b-tooltip
                      v-if="!Boolean(attendanceReview.declaracaoDeComparecimento)"
                      target="declaracao-de-comparecimento"
                      placement="top"
                    >
                      Para imprimir este documento, <br />
                      preencha o campo dentro da consulta
                    </b-tooltip>
                  </label>
                </td>
              </tr>

              <tr>
                <td colspan="3">
                  <hr class="hr-table" />
                </td>
                <td colspan="2">
                  <hr class="hr-table" />
                </td>
              </tr>

              <tr>
                <td class="tdLabel">
                  Declaração de comparecimento (acompanhante)
                </td>
                <td class="text-center">
                  <label class="checkbox" align="center">
                    <input
                      type="checkbox"
                      v-model="checkAttendanceReview.declaracaoDeComparecimentoAcompanhante"
                      :disabled="!Boolean(attendanceReview.declaracaoDeComparecimentoAcompanhante)"
                    />
                    <span
                      class="checkmark"
                      id="declaracao-de-comparecimento-acompanhante"
                    >
                      <Check />
                    </span>
                    <b-tooltip
                      v-if="!Boolean(attendanceReview.declaracaoDeComparecimentoAcompanhante)"
                      target="declaracao-de-comparecimento-acompanhante"
                      placement="top"
                    >
                      Para imprimir este documento, <br />
                      preencha o campo dentro da consulta
                    </b-tooltip>
                  </label>
                </td>
              </tr>

              <tr>
                <td colspan="3">
                  <hr class="hr-table" />
                </td>
                <td colspan="2">
                  <hr class="hr-table" />
                </td>
              </tr>

              <tr>
                <td class="tdLabel">Atestado</td>
                <td class="text-center">
                  <label class="checkbox" align="center">
                    <input
                      type="checkbox"
                      v-model="checkAttendanceReview.atestado"
                      :disabled="!Boolean(attendanceReview.atestado)"
                    />
                    <span class="checkmark" id="atestado">
                      <Check />
                    </span>
                    <b-tooltip
                      v-if="!Boolean(attendanceReview.atestado)"
                      target="atestado"
                      placement="top"
                    >
                      Para imprimir este documento, <br />
                      preencha o campo dentro da consulta
                    </b-tooltip>
                  </label>
                </td>
              </tr>

              <tr>
                <td colspan="3">
                  <hr class="hr-table" />
                </td>
                <td colspan="2">
                  <hr class="hr-table" />
                </td>
              </tr>

              <tr>
                <td class="tdLabel">Laudo médico</td>
                <td class="text-center">
                  <label class="checkbox" align="center">
                    <input
                      type="checkbox"
                      v-model="checkAttendanceReview.laudo"
                      :disabled="!Boolean(attendanceReview.laudo)"
                    />
                    <span class="checkmark" id="laudo-medico">
                      <Check />
                    </span>
                    <b-tooltip
                      v-if="!Boolean(attendanceReview.laudo)"
                      target="laudo-medico"
                      placement="top"
                    >
                      Para imprimir este documento, <br />
                      preencha o campo dentro da consulta
                    </b-tooltip>
                  </label>
                </td>
              </tr>

              <tr>
                <td colspan="3">
                  <hr class="hr-table" />
                </td>
                <td colspan="2">
                  <hr class="hr-table" />
                </td>
              </tr>

              <tr>
                <td class="tdLabel">Mapemanto de retina</td>
                <td class="text-center">
                  <label class="checkbox" align="center">
                    <input
                      type="checkbox"
                      v-model="checkAttendanceReview.mapeamentoRetina"
                      :disabled="!Boolean(attendanceReview.mapeamentoRetina)"
                    />
                    <span class="checkmark" id="mapeamento-retina">
                      <Check />
                    </span>
                    <b-tooltip
                      v-if="!Boolean(attendanceReview.mapeamentoRetina)"
                      target="mapeamento-retina"
                      placement="top"
                    >
                      Para imprimir este documento, <br />
                      preencha o campo dentro da consulta
                    </b-tooltip>
                  </label>
                </td>
              </tr>
            </table>
          </div>
        </div>

        <b-row class="mt-4">
          <b-col class="text-right">
            <b-button
              variant="primary"
              :disabled="Object.values(this.checkAttendanceReview).every(element => !element)"
              @click="createDocumentAndPrint"
            >
              Imprimir
            </b-button>
          </b-col>
        </b-row>
      </div>
    </b-modal>
  </div>
</template>

<script>
import Close from '@/assets/icons/close.svg'
import Check from '@/assets/icons/check.svg'
import { getToPrint } from '@/utils/documentHelper'

export default {
  name: 'PrintMedicalRecordsModal',
  props: {
    attendanceReview: Object,
    attendanceId: String,
    formGroups: Object,
    medicines: Object,
    medicinesAntimicrobianos: Object,
    patientData: Object,
    professionalData: Object,
    professionalCrm: Array,
    clinicsData: Object,
  },
  components: {
    Close,
    Check
  },
  data() {
    return {
      checkAttendanceReview: {},
      toPrint: []
    }
  },
  methods: {
    async onShow() {
      this.checkAttendanceReview = Object.assign({}, this.attendanceReview)
    },
    async createDocumentAndPrint() {
      this.toPrint = getToPrint(this.checkAttendanceReview, this.medicines, this.medicinesAntimicrobianos);

      const data = {
        attendanceId: this.attendanceId,
        toPrint: this.toPrint,
        toSign: [],
        quickPrint: true,
        certContent: null,
        service: null,
      }

      try {
        await this.api.finishAttendance(data);
        this.$toast.success('Documentos enviados para impressão');
        this.$bvModal.hide('print-medical-records-modal');
      } catch(err) {
        this.$toast.error(err.message);
      }
    },
  },
}
</script>

<style lang="scss">
#print-medical-records-modal {
  font-family: 'Nunito Sans';
  .modal-body {
    padding: 0 !important;

    .header {
      padding: 24px;
      border-bottom: 1px solid var(--neutral-200);

      .title {
        font-weight: 600;
        font-size: 18px;
        color: var(--type-active);
        margin: 0;
      }

      .close {
        width: 24px;
        height: 24px;
        fill: black;
        cursor: pointer;
      }
    }

    .body {
      table {
        width: -webkit-fill-available;
      }

      .table-header {
        font-family: Nunito Sans;
        font-style: normal;
        font-size: 14px;
        line-height: 150%;
        color: var(--type-active);
      }

      .hr-table {
        margin-top: 0px;
        margin-bottom: 0px;
      }

      padding: 24px;

      .title {
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        color: var(--type-active);
        margin-bottom: 8px;
      }

      .text {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: var(--type-active);
        margin-bottom: 32px;
      }

      .checkbox {
        margin-top: -12px;
        display: initial !important;
      }
      .checkbox.bold {
        font-weight: 700;
        margin-bottom: 16px;
      }

      input[type='checkbox']:disabled ~ .checkmark {
        background-color: var(--neutral-200);
      }

      .tdLabel {
        font-size: 16px;
        color: var(--type-active);
        font-weight: 400;
        padding: 10px 0px;
      }
    }
  }
}
</style>
